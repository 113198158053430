/* AutoGenerated Code, changes may be overwritten
* INPUT GRAMMAR:
* Program := Concatenation | While | Addition | Subtraction
* Addition := '\s*' left=Variable '\s*:=\s*' right=Variable '\s*\+\s*' c=Constant
* Subtraction := '\s*' left=Variable '\s*:=\s*' right=Variable '\s*\-\s*' c=Constant
* Concatenation := '\s*' first=Program '\s*;\s*' second=Program '\s*'
* Constant := '\s*' _value='[0-9]+' '\s*'
*   .value = number { return parseInt(this._value); }
* Variable := '\s*' 'x'_i='[0-9]+' '\s*'
*   .i = number { return parseInt(this._i); }
* While := '\s*WHILE\s*' v=Variable '\s*!=\s*0\s*DO\s*' body=Program '\s*END\s*'
*/
type Nullable<T> = T | null;
type $$RuleType<T> = () => Nullable<T>;
export interface ASTNodeIntf {
    kind: ASTKinds;
}
export enum ASTKinds {
    Program_1 = "Program_1",
    Program_2 = "Program_2",
    Program_3 = "Program_3",
    Program_4 = "Program_4",
    Addition = "Addition",
    Subtraction = "Subtraction",
    Concatenation = "Concatenation",
    Constant = "Constant",
    Variable = "Variable",
    While = "While",
}
export type Program = Program_1 | Program_2 | Program_3 | Program_4;
export type Program_1 = Concatenation;
export type Program_2 = While;
export type Program_3 = Addition;
export type Program_4 = Subtraction;
export interface Addition {
    kind: ASTKinds.Addition;
    left: Variable;
    right: Variable;
    c: Constant;
}
export interface Subtraction {
    kind: ASTKinds.Subtraction;
    left: Variable;
    right: Variable;
    c: Constant;
}
export interface Concatenation {
    kind: ASTKinds.Concatenation;
    first: Program;
    second: Program;
}
export class Constant {
    public kind: ASTKinds.Constant = ASTKinds.Constant;
    public _value: string;
    public value: number;
    constructor(_value: string){
        this._value = _value;
        this.value = ((): number => {
        return parseInt(this._value);
        })();
    }
}
export class Variable {
    public kind: ASTKinds.Variable = ASTKinds.Variable;
    public _i: string;
    public i: number;
    constructor(_i: string){
        this._i = _i;
        this.i = ((): number => {
        return parseInt(this._i);
        })();
    }
}
export interface While {
    kind: ASTKinds.While;
    v: Variable;
    body: Program;
}
export class Parser {
    private readonly input: string;
    private pos: PosInfo;
    private negating: boolean = false;
    private memoSafe: boolean = true;
    constructor(input: string) {
        this.pos = {overallPos: 0, line: 1, offset: 0};
        this.input = input;
    }
    public reset(pos: PosInfo) {
        this.pos = pos;
    }
    public finished(): boolean {
        return this.pos.overallPos === this.input.length;
    }
    public clearMemos(): void {
        this.$scope$Program$memo.clear();
    }
    protected $scope$Program$memo: Map<number, [Nullable<Program>, PosInfo]> = new Map();
    public matchProgram($$dpth: number, $$cr?: ErrorTracker): Nullable<Program> {
        const fn = () => {
            return this.choice<Program>([
                () => this.matchProgram_1($$dpth + 1, $$cr),
                () => this.matchProgram_2($$dpth + 1, $$cr),
                () => this.matchProgram_3($$dpth + 1, $$cr),
                () => this.matchProgram_4($$dpth + 1, $$cr),
            ]);
        };
        const $scope$pos = this.mark();
        const memo = this.$scope$Program$memo.get($scope$pos.overallPos);
        if(memo !== undefined) {
            this.reset(memo[1]);
            return memo[0];
        }
        const $scope$oldMemoSafe = this.memoSafe;
        this.memoSafe = false;
        this.$scope$Program$memo.set($scope$pos.overallPos, [null, $scope$pos]);
        let lastRes: Nullable<Program> = null;
        let lastPos: PosInfo = $scope$pos;
        for(;;) {
            this.reset($scope$pos);
            const res = fn();
            const end = this.mark();
            if(end.overallPos <= lastPos.overallPos)
                break;
            lastRes = res;
            lastPos = end;
            this.$scope$Program$memo.set($scope$pos.overallPos, [lastRes, lastPos]);
        }
        this.reset(lastPos);
        this.memoSafe = $scope$oldMemoSafe;
        return lastRes;
    }
    public matchProgram_1($$dpth: number, $$cr?: ErrorTracker): Nullable<Program_1> {
        return this.matchConcatenation($$dpth + 1, $$cr);
    }
    public matchProgram_2($$dpth: number, $$cr?: ErrorTracker): Nullable<Program_2> {
        return this.matchWhile($$dpth + 1, $$cr);
    }
    public matchProgram_3($$dpth: number, $$cr?: ErrorTracker): Nullable<Program_3> {
        return this.matchAddition($$dpth + 1, $$cr);
    }
    public matchProgram_4($$dpth: number, $$cr?: ErrorTracker): Nullable<Program_4> {
        return this.matchSubtraction($$dpth + 1, $$cr);
    }
    public matchAddition($$dpth: number, $$cr?: ErrorTracker): Nullable<Addition> {
        return this.run<Addition>($$dpth,
            () => {
                let $scope$left: Nullable<Variable>;
                let $scope$right: Nullable<Variable>;
                let $scope$c: Nullable<Constant>;
                let $$res: Nullable<Addition> = null;
                if (true
                    && this.regexAccept(String.raw`(?:\s*)`, $$dpth + 1, $$cr) !== null
                    && ($scope$left = this.matchVariable($$dpth + 1, $$cr)) !== null
                    && this.regexAccept(String.raw`(?:\s*:=\s*)`, $$dpth + 1, $$cr) !== null
                    && ($scope$right = this.matchVariable($$dpth + 1, $$cr)) !== null
                    && this.regexAccept(String.raw`(?:\s*\+\s*)`, $$dpth + 1, $$cr) !== null
                    && ($scope$c = this.matchConstant($$dpth + 1, $$cr)) !== null
                ) {
                    $$res = {kind: ASTKinds.Addition, left: $scope$left, right: $scope$right, c: $scope$c};
                }
                return $$res;
            });
    }
    public matchSubtraction($$dpth: number, $$cr?: ErrorTracker): Nullable<Subtraction> {
        return this.run<Subtraction>($$dpth,
            () => {
                let $scope$left: Nullable<Variable>;
                let $scope$right: Nullable<Variable>;
                let $scope$c: Nullable<Constant>;
                let $$res: Nullable<Subtraction> = null;
                if (true
                    && this.regexAccept(String.raw`(?:\s*)`, $$dpth + 1, $$cr) !== null
                    && ($scope$left = this.matchVariable($$dpth + 1, $$cr)) !== null
                    && this.regexAccept(String.raw`(?:\s*:=\s*)`, $$dpth + 1, $$cr) !== null
                    && ($scope$right = this.matchVariable($$dpth + 1, $$cr)) !== null
                    && this.regexAccept(String.raw`(?:\s*\-\s*)`, $$dpth + 1, $$cr) !== null
                    && ($scope$c = this.matchConstant($$dpth + 1, $$cr)) !== null
                ) {
                    $$res = {kind: ASTKinds.Subtraction, left: $scope$left, right: $scope$right, c: $scope$c};
                }
                return $$res;
            });
    }
    public matchConcatenation($$dpth: number, $$cr?: ErrorTracker): Nullable<Concatenation> {
        return this.run<Concatenation>($$dpth,
            () => {
                let $scope$first: Nullable<Program>;
                let $scope$second: Nullable<Program>;
                let $$res: Nullable<Concatenation> = null;
                if (true
                    && this.regexAccept(String.raw`(?:\s*)`, $$dpth + 1, $$cr) !== null
                    && ($scope$first = this.matchProgram($$dpth + 1, $$cr)) !== null
                    && this.regexAccept(String.raw`(?:\s*;\s*)`, $$dpth + 1, $$cr) !== null
                    && ($scope$second = this.matchProgram($$dpth + 1, $$cr)) !== null
                    && this.regexAccept(String.raw`(?:\s*)`, $$dpth + 1, $$cr) !== null
                ) {
                    $$res = {kind: ASTKinds.Concatenation, first: $scope$first, second: $scope$second};
                }
                return $$res;
            });
    }
    public matchConstant($$dpth: number, $$cr?: ErrorTracker): Nullable<Constant> {
        return this.run<Constant>($$dpth,
            () => {
                let $scope$_value: Nullable<string>;
                let $$res: Nullable<Constant> = null;
                if (true
                    && this.regexAccept(String.raw`(?:\s*)`, $$dpth + 1, $$cr) !== null
                    && ($scope$_value = this.regexAccept(String.raw`(?:[0-9]+)`, $$dpth + 1, $$cr)) !== null
                    && this.regexAccept(String.raw`(?:\s*)`, $$dpth + 1, $$cr) !== null
                ) {
                    $$res = new Constant($scope$_value);
                }
                return $$res;
            });
    }
    public matchVariable($$dpth: number, $$cr?: ErrorTracker): Nullable<Variable> {
        return this.run<Variable>($$dpth,
            () => {
                let $scope$_i: Nullable<string>;
                let $$res: Nullable<Variable> = null;
                if (true
                    && this.regexAccept(String.raw`(?:\s*)`, $$dpth + 1, $$cr) !== null
                    && this.regexAccept(String.raw`(?:x)`, $$dpth + 1, $$cr) !== null
                    && ($scope$_i = this.regexAccept(String.raw`(?:[0-9]+)`, $$dpth + 1, $$cr)) !== null
                    && this.regexAccept(String.raw`(?:\s*)`, $$dpth + 1, $$cr) !== null
                ) {
                    $$res = new Variable($scope$_i);
                }
                return $$res;
            });
    }
    public matchWhile($$dpth: number, $$cr?: ErrorTracker): Nullable<While> {
        return this.run<While>($$dpth,
            () => {
                let $scope$v: Nullable<Variable>;
                let $scope$body: Nullable<Program>;
                let $$res: Nullable<While> = null;
                if (true
                    && this.regexAccept(String.raw`(?:\s*WHILE\s*)`, $$dpth + 1, $$cr) !== null
                    && ($scope$v = this.matchVariable($$dpth + 1, $$cr)) !== null
                    && this.regexAccept(String.raw`(?:\s*!=\s*0\s*DO\s*)`, $$dpth + 1, $$cr) !== null
                    && ($scope$body = this.matchProgram($$dpth + 1, $$cr)) !== null
                    && this.regexAccept(String.raw`(?:\s*END\s*)`, $$dpth + 1, $$cr) !== null
                ) {
                    $$res = {kind: ASTKinds.While, v: $scope$v, body: $scope$body};
                }
                return $$res;
            });
    }
    public test(): boolean {
        const mrk = this.mark();
        const res = this.matchProgram(0);
        const ans = res !== null;
        this.reset(mrk);
        return ans;
    }
    public parse(): ParseResult {
        const mrk = this.mark();
        const res = this.matchProgram(0);
        if (res)
            return {ast: res, errs: []};
        this.reset(mrk);
        const rec = new ErrorTracker();
        this.clearMemos();
        this.matchProgram(0, rec);
        const err = rec.getErr()
        return {ast: res, errs: err !== null ? [err] : []}
    }
    public mark(): PosInfo {
        return this.pos;
    }
    private loop<T>(func: $$RuleType<T>, star: boolean = false): Nullable<T[]> {
        const mrk = this.mark();
        const res: T[] = [];
        for (;;) {
            const t = func();
            if (t === null) {
                break;
            }
            res.push(t);
        }
        if (star || res.length > 0) {
            return res;
        }
        this.reset(mrk);
        return null;
    }
    private run<T>($$dpth: number, fn: $$RuleType<T>): Nullable<T> {
        const mrk = this.mark();
        const res = fn()
        if (res !== null)
            return res;
        this.reset(mrk);
        return null;
    }
    private choice<T>(fns: Array<$$RuleType<T>>): Nullable<T> {
        for (const f of fns) {
            const res = f();
            if (res !== null) {
                return res;
            }
        }
        return null;
    }
    private regexAccept(match: string, dpth: number, cr?: ErrorTracker): Nullable<string> {
        return this.run<string>(dpth,
            () => {
                const reg = new RegExp(match, "y");
                const mrk = this.mark();
                reg.lastIndex = mrk.overallPos;
                const res = this.tryConsume(reg);
                if(cr) {
                    cr.record(mrk, res, {
                        kind: "RegexMatch",
                        // We substring from 3 to len - 1 to strip off the
                        // non-capture group syntax added as a WebKit workaround
                        literal: match.substring(3, match.length - 1),
                        negated: this.negating,
                    });
                }
                return res;
            });
    }
    private tryConsume(reg: RegExp): Nullable<string> {
        const res = reg.exec(this.input);
        if (res) {
            let lineJmp = 0;
            let lind = -1;
            for (let i = 0; i < res[0].length; ++i) {
                if (res[0][i] === "\n") {
                    ++lineJmp;
                    lind = i;
                }
            }
            this.pos = {
                overallPos: reg.lastIndex,
                line: this.pos.line + lineJmp,
                offset: lind === -1 ? this.pos.offset + res[0].length : (res[0].length - lind - 1)
            };
            return res[0];
        }
        return null;
    }
    private noConsume<T>(fn: $$RuleType<T>): Nullable<T> {
        const mrk = this.mark();
        const res = fn();
        this.reset(mrk);
        return res;
    }
    private negate<T>(fn: $$RuleType<T>): Nullable<boolean> {
        const mrk = this.mark();
        const oneg = this.negating;
        this.negating = !oneg;
        const res = fn();
        this.negating = oneg;
        this.reset(mrk);
        return res === null ? true : null;
    }
    private memoise<K>(rule: $$RuleType<K>, memo: Map<number, [Nullable<K>, PosInfo]>): Nullable<K> {
        const $scope$pos = this.mark();
        const $scope$memoRes = memo.get($scope$pos.overallPos);
        if(this.memoSafe && $scope$memoRes !== undefined) {
        this.reset($scope$memoRes[1]);
        return $scope$memoRes[0];
        }
        const $scope$result = rule();
        if(this.memoSafe)
        memo.set($scope$pos.overallPos, [$scope$result, this.mark()]);
        return $scope$result;
    }
}
export function parse(s: string): ParseResult {
    const p = new Parser(s);
    return p.parse();
}
export interface ParseResult {
    ast: Nullable<Program>;
    errs: SyntaxErr[];
}
export interface PosInfo {
    readonly overallPos: number;
    readonly line: number;
    readonly offset: number;
}
export interface RegexMatch {
    readonly kind: "RegexMatch";
    readonly negated: boolean;
    readonly literal: string;
}
export type EOFMatch = { kind: "EOF"; negated: boolean };
export type MatchAttempt = RegexMatch | EOFMatch;
export class SyntaxErr {
    public pos: PosInfo;
    public expmatches: MatchAttempt[];
    constructor(pos: PosInfo, expmatches: MatchAttempt[]) {
        this.pos = pos;
        this.expmatches = [...expmatches];
    }
    public toString(): string {
        return `Syntax Error at line ${this.pos.line}:${this.pos.offset}. Expected one of ${this.expmatches.map(x => x.kind === "EOF" ? " EOF" : ` ${x.negated ? 'not ': ''}'${x.literal}'`)}`;
    }
}
class ErrorTracker {
    private mxpos: PosInfo = {overallPos: -1, line: -1, offset: -1};
    private regexset: Set<string> = new Set();
    private pmatches: MatchAttempt[] = [];
    public record(pos: PosInfo, result: any, att: MatchAttempt) {
        if ((result === null) === att.negated)
            return;
        if (pos.overallPos > this.mxpos.overallPos) {
            this.mxpos = pos;
            this.pmatches = [];
            this.regexset.clear()
        }
        if (this.mxpos.overallPos === pos.overallPos) {
            if(att.kind === "RegexMatch") {
                if(!this.regexset.has(att.literal))
                    this.pmatches.push(att);
                this.regexset.add(att.literal);
            } else {
                this.pmatches.push(att);
            }
        }
    }
    public getErr(): SyntaxErr | null {
        if (this.mxpos.overallPos !== -1)
            return new SyntaxErr(this.mxpos, this.pmatches);
        return null;
    }
}